import Image1 from '../images/main/list1/PQC.png';
import Image2 from '../images/main/list1/OIT.png';
import Image3 from '../images/main/list1/DPS.png';
import Image4 from '../images/main/list1/NRT.png';
import Image5 from '../images/main/list1/CNX.png';
import Image6 from '../images/main/list1/TPE.png';
import Image7 from '../images/main/list2/KIX.png';
import Image8 from '../images/main/list2/OKA.png';
import Image9 from '../images/main/list2/FUK.png';
import Image10 from '../images/main/list2/NGO.png';

export const images = {
  PQC: Image1,
  OIT: Image2,
  DPS: Image3,
  NRT: Image4,
  CNX: Image5,
  TPE: Image6,
  KIX: Image7,
  OKA: Image8,
  FUK: Image9,
  NGO: Image10,
};
